// Vendors
import $ from "jquery";
import Vue from 'vue';

// Custom Vue Components
// import progressScroller from './components/progress.vue';
import stickyTop from './components/stickyTop.vue';
// import magnificPopupModal from './components/magnificPopupmodal';

// Vue vendor modules
import slick from 'vue-slick';
import VueScrollTo from "vue-scrollto";
import VueScrollReveal from "vue-scroll-reveal";
import VueParallaxJs from 'vue-parallax-js';
//import VueTypedJs from 'vue-typed-js';

// Other JS vendor modules
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class App {

  constructor() {

   window.jQuery = $;
   window.$ = $;

   Vue.config.productionTip = false;

    Vue.use(VueScrollTo, {
      container: "body",
      duration: 1000,
      easing: "ease",
      offset: 0,
      force: true,
      cancelable: true,
      onStart: function (element) {
      },
      onDone: function (element) {
      },
      onCancel: function (element) {
      },
      x: false,
      y: true
    });

    Vue.use(VueScrollReveal, {
      class: 'v-scroll-reveal',
      duration: 1000,
      scale: 1,
      distance: '16px',
      mobile: true,
      viewFactor: .2,
    });

    Vue.use(VueParallaxJs)

    //Vue.use(VueTypedJs)

  }

  init() {

    new Vue({
      el: '#app',
      delimiters: ['<%', '%>'],
      components: {

        // Components
        // 'progressScroller': progressScroller,
        'stickyTop': stickyTop,

        // Vendors
        'slick': slick,
      },

      data() {
        return {
          value: 5,
          slickOptions: {
            slidesToShow: 3,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            arrows: true,
            dots: true,
            centerMode: true,
            focusOnSelect: false,
            infinite: true,
            accessibility: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 639,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                }
              }
            ]
          },
          menuIsActive: false,
          window: {
            width: 0,
            height: 0,
          },
          maxNavHeight: 0,
          subNavsContainers: [],
          subNavs: [],
        }
      },

      mounted() {

        this.subNavsContainers = this.$el.querySelectorAll('.c-nav-menu-item-has-sub');
        this.subNavs = this.$el.querySelectorAll('.c-nav-menu-sub');
        this.subNavsContainers.forEach((element) => {
          const node = document.createElement('span');
          node.classList.add("toggle-link");
          element.insertBefore(node, element.firstChild);
        })

        window.addEventListener('resize', this.handleResize);
        this.handleResize();

      },

      watch: {

        menuIsActive() {

          if (this.menuIsActive) {
            this.maxNavHeight = this.window.height - this.$refs.nav.clientHeight;
          } else {
            this.maxNavHeight = 0;
          }

        }

      },

      methods: {

        /*

        openModal(modal) {
          this.$refs[modal].open()
        },

        */

        toggleActive() {
          // https://medium.com/jsdownunder/locking-body-scroll-for-all-devices-22def9615177
          this.menuIsActive = !this.menuIsActive
          if (this.menuIsActive) {
            disableBodyScroll();
          } else  {
            enableBodyScroll();
          }
        },

        menuClicked(event) {
          let targetClassNames = event.target.className.split(" ");
          targetClassNames.filter(e => {
            if (e === "toggle-link"){
              event.target.classList.toggle('active')
              const subMenu = event.target.parentNode.querySelector('ul');
              subMenu.classList.toggle('active');
             }
          });
        },

        handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;

          if (this.window.width >= 960) {
            this.menuIsActive = false;
            this.subNavs.forEach((element) => {
              element.classList.remove('active');
            })
          }
        },

      }

    });
  }
}

$(() => {

  const app = new App();

  app.init();

});