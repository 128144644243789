<template>
  <div class="c-sticky-top" :style="myStyle" :class="extraClass">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        myStyle: {},
        scrollY: 0,
        didScroll: false,
        lastScrollTop: 0,
        delta: 5,
        rectHeight: 0,
        extraClass: '',
      }
    },
    mounted() {
      this.originalTop = this.$el.offsetTop;
      this.scrollY = document.documentElement.scrollTop;
      this.rectHeight = this.$el.getBoundingClientRect().height;
      const that = this;
      // document.getElementsByClassName("c-sticky-top")[0].setAttribute("style", "padding-top:" + this.rectHeight + "px;");
      window.addEventListener('scroll', (event) => {
        this.scrollY = Math.round(window.scrollY);
      });
      setInterval(function () {
        if (that.didScroll) {
          that.setSticky();
          that.didScroll = false;
        }
      }, 250);
    },
    watch: {
      scrollY() {
        this.didScroll = true;
      }
    },
    methods: {
      setSticky() {
        // Make sure they scroll more than delta
        if (Math.abs(this.lastScrollTop - this.scrollY) <= this.delta){
          return;
        } else {
          this.extraClass = 'isFixed';

          if (this.scrollY <= this.rectHeight) {
            this.extraClass = '';
          } else {
            this.extraClass = 'isFixed';
          }

          if (this.scrollY > this.lastScrollTop && this.scrollY > this.rectHeight) {
            // Scroll Down
            this.$set(this.myStyle, 'top', `-` + this.rectHeight + `px`);
          } else {
            // Scroll Up
            this.$delete(this.myStyle, 'top');
          }
        }
        this.lastScrollTop = this.scrollY;
      }
    }
  };
</script>

<style>
  .c-sticky-top {
    transition: all 0.2s ease-in-out;
    z-index: 99;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .c-sticky-top.isFixed {
    background-color: #ffffff;
  }
</style>
